import createCRUDPage from "pages/Apps/Core/CreateCrudComponent";

// export const Accounts = createCRUDPage(
//   "account-crud",
//   "Datacenter Accounts",
//   null
// );
export const DatacenterAccounts = createCRUDPage(
  "account-crud",
  "Datacenter Accounts",
  "thedoor"
);
export const BonjoAccounts = createCRUDPage(
  "account-crud",
  "Datacenter Accounts",
  "bonjoapp"
);
